<template>
  <div>
    <div id="map"></div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "map-view",
  data() {
    return {
      AMap: null,
      map: null,
      infoWindow: null,
      // 标记点的数据
    };
  },

  mounted() {
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    initAMap() {
      AMapLoader.load({
        key: "aca2405032cf7ecfe3aa31b9bc70e77f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.AMap = AMap;
        this.map = new AMap.Map("map", {
          // 设置地图容器id
          viewMode: "2D", // 是否为3D地图模式
          zoom: 18, // 初始化地图级别
          center: [109.543831, 24.370582], // 初始化地图中心点位置
          resizeEnable: true,
        });
        this.openInfo();
      });
    },
    openInfo() {
      //构建信息窗体中显示的内容
      var info = [];
      info.push(
        '<div style="padding:7px 0px 0px 0px;"><h4>广西保辰信息科技有限公司（柳州区）</h4>'
      );
      info.push("<p class='input-item'>电话 : 0771-5770288 </p>");
      info.push(
        "<p class='input-item'>地址 ：广西柳州市龙湖路13号（市民服务中心北楼7楼前台）</p></div></div>"
      );

      this.infoWindow = new this.AMap.InfoWindow({
        content: info.join(""), //使用默认信息窗体框样式，显示信息内容
      });

      this.infoWindow.open(this.map, this.map.getCenter());
    },
  },
};
</script>
<style scoped>
#map {
  width: 100%;
  height: 400px;
}
</style>
