<template>
  <div class="kefu">
    <button class="btn" @click="showImageModal = true"></button>
    <div v-if="showImageModal" class="image-modal">
      <el-button
        icon="el-icon-close"
        type="text"
        size="small"
        @click="showImageModal = false"
        style="position: absolute; top: -3px; right: 2px"
      ></el-button>
      <!-- <el-button type="text">文字按钮</el-button> -->
      <img :src="imgList[0].picLoad" width="80px" height="80px" />
    </div>
    <div class="pic">
      <img src="./../assets/images/kefu.png" />
    </div>

    <div class="line"></div>
  </div>
</template>
<script>
import article from "@/api/article";
export default {
  name: "service",
  data() {
    return {
      showImageModal: false,
      // imageUrl: "./../assets/images/ewm.jpg",
    };
  },
  mounted() {
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      article.bannerList({ belongPage: "foot" }).then((res) => {
        this.imgList = res.data.data.records;
        console.log("2222", this.imgList);
      });
    },
  },
};
</script>
<style scoped lang="less">
.kefu {
  width: 66px;
  height: 66px;
  background-color: #3494ec;
  position: fixed;
  bottom: 20%;
  right: 60px;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0 0 20px rgba(52, 148, 236, 0.8);
  .pic {
    position: absolute;
    top: 6px;
    left: 10px;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    img {
      width: 85%;
      height: 85%;
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .line {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #40bcff;
    position: absolute;
    top: 0;
    left: 0;
    animation: emit 1s infinite;
  }
  .image-modal {
    position: absolute;
    top: 6px;
    right: 10px;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  @keyframes emit {
    0% {
    }
    100% {
      border-width: 1px;
      opacity: 0;
      transform: scale(1.5);
    }
  }
}
</style>
