<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-info">
        <router-link to="/">
          <img src="./../assets/images/logo.png"
        /></router-link>
        <div class="company">广西保辰信息科技有限公司</div>
        <div class="company-info">Copyright © 2024 保辰信息-官方网站</div>
        <div class="company-info">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >桂ICP备2022006656号</a
          >
        </div>
      </div>
      <div class="footer-nav">
        <div class="nav-name">导航</div>
        <div>
          <ul>
            <li><router-link to="/" class="navtype">首页</router-link></li>
            <li>
              <router-link to="/invitation" class="navtype"
                >广西招投标公告</router-link
              >
            </li>
            <li>
              <router-link to="/camodule" class="navtype"
                >西部CA办理入口</router-link
              >
            </li>
            <li>
              <router-link to="/projectservices" class="navtype"
                >服务项目</router-link
              >
            </li>
            <li>
              <router-link to="/AllNews" class="navtype">新闻资讯</router-link>
            </li>
            <li>
              <router-link to="/about" class="navtype">关于我们</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-about">
        <div class="about">联系我们</div>
        <div class="footer-add">
          <p>
            <b>{{ info.address.webContentTitle }}</b
            >{{ info.address.webContentText }}
          </p>
          <p>
            {{ info.address2.webContentTitle
            }}{{ info.address2.webContentText }}
          </p>
        </div>
        <div class="main">
          <div class="contact">
            <div>
              <b>{{ info.phone.webContentTitle }}</b
              ><span>{{ info.phone.webContentText }}</span>
            </div>

            <div>{{ info.mobile.webContentText }}</div>
            <div>{{ info.mobile2.webContentText }}</div>
          </div>
          <div class="ewm">
            <div class="ewm-info" v-for="(item, index) in imgList" :key="index">
              <img width="80px" height="80px" :src="item.picLoad" />
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import article from "@/api/article";
export default {
  name: "Footer",
  data() {
    return {
      info: {
        address: {
          webContentText: "",
          webContentTitle: "",
        },
        address2: {
          webContentText: "",
          webContentTitle: "",
        },
        phone: {
          webContentText: "",
          webContentTitle: "",
        },
        msg: {
          webContentText: "",
          webContentTitle: "",
        },
        mobile: {
          webContentText: "",
          webContentTitle: "",
        },
        mobile2: {
          webContentText: "",
          webContentTitle: "",
        },
      },
      infoData: [],
      imgList: [],
    };
  },

  mounted() {
    this.handleGetData();
    this.getBannerList();
  },

  methods: {
    handleGetData() {
      article
        .aboutData({ belongPage: "about" })
        .then((res) => {
          this.infoData = res.data.data.records;
          this.infoData.forEach((item) => (this.info[item.notes] = item));
          // console.log(this.info);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBannerList() {
      article.bannerList({ belongPage: "foot" }).then((res) => {
        this.imgList = res.data.data.records;
      });
    },
  },
};
</script>
<style scoped lang="less">
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.footer {
  margin-top: 80px;
  background-color: #212121;
  width: 100%;
  height: 280px;
  min-width: 1300px;
  .footer-content {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    // grid-template-columns: repeat(3, 1fr);
    // grid-column-gap: 40px;
    padding-top: 40px;
    color: #f0f0f0;
    .footer-info {
      img {
        width: 288px;
        height: 53px;
      }
      .company {
        font-size: 18px;
        font-weight: 500;
        padding-top: 20px;
      }
      .company-info {
        font-size: 14px;
        padding-top: 20px;
        color: #bdb8b8;
        a {
          color: #bdb8b8;
        }
      }
    }
    .footer-nav {
      padding-left: 30px;
      text-align: left;
      ul li {
        list-style-type: none;
        font-size: 14px;
        padding-top: 10px;
        color: #bdb8b8;
        .navtype {
          color: #bdb8b8;
          cursor: pointer;
        }
      }
    }
    .footer-about {
      text-align: left;
      font-size: 14px;
      color: #bdb8b8;
      .about {
        font-size: 16px;
        color: #fff;
      }
      .footer-add {
        p {
          padding: 5px 0;
        }
      }
      // div:not(:first-child) {
      //   // padding-top: 10px;
      // }
    }
    .main {
      display: flex;

      .contact {
        margin-right: 30px;
        div {
          padding: 5px 0;
        }
      }
      img {
        padding: 10px;
      }
      .ewm {
        display: flex;
        align-items: center;
        padding: 20px;
        .ewm-info {
          display: flex;
          flex-direction: column;
          text-align: center;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
