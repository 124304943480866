import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import About from "../views/about.vue";
import ProjectServices from "../views/projectservices.vue";
import ProjectList from "../views/projectlist.vue";
import ProjectDetail from "../views/projectdetail.vue";
import NoticeList from "../views/noticelist.vue";
import NoticeDetail from "../views/noticedetail.vue";
import Search from "../views/search.vue";
import news from "../views/news.vue";
import GroupNews from "../views/News/GroupNews.vue";
import MediaCoverage from "../views/News/MediaCoverage.vue";
import TradeNews from "../views/News/TradeNews.vue";
import AllNews from "../views/News/AllNews.vue";
import newsDetail from "../views/News/newsdetal.vue";
import invitation from "../views/invitation.vue";
import Cailist from "../views/procure/cailist.vue";
import cailistDetal from "../views/procure/info/listinfo.vue";
import caitableDetal from "../views/procure/info/tableinfo.vue";
import CaiTable from "../views/procure/caitable.vue";
import ImgeCourse from "../views/CA/imgecourse.vue";
import CaModule from "../views/CA/camodule.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/projectservices",
    name: "projectservices",
    component: ProjectServices,
    meta: {
      title: "服务项目",
    },
  },
  {
    path: "/noticelist",
    name: "noticelist",
    component: NoticeList,
    meta: {
      title: "通知公告列表",
    },
  },

  {
    path: "/projectlist",
    name: "projectlist",
    component: ProjectList,
    meta: {
      title: "项目服务列表",
    },
  },
  {
    path: "/projectdetail",
    name: "projectdetail",
    component: ProjectDetail,
    meta: {
      title: "项目服务详情",
    },
  },

  {
    path: "/noticedetail",
    name: "noticedetail",
    component: NoticeDetail,
    meta: {
      title: "通知公告详情",
    },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      title: "搜索详情",
    },
  },
  {
    path: "/news",
    name: "news",
    component: news,

    redirect: "/AllNews",
    children: [
      {
        path: "/AllNews",
        name: "AllNews",
        component: AllNews,
        meta: {
          title: "全部资讯",
        },
      },
      {
        path: "/GroupNews",
        component: GroupNews,
        name: "GroupNews",
        meta: {
          title: "招投标资讯",
        },
      },
      {
        path: "/MediaCoverage",
        component: MediaCoverage,
        name: "MediaCoverage",
        meta: {
          title: "CA动态",
        },
      },
      {
        path: "/TradeNews",
        name: "TradeNews",
        component: TradeNews,
        meta: {
          title: "财政资讯",
        },
      },
    ],
    meta: {
      title: "新闻资讯",
    },
  },
  {
    path: "/newsdetal",
    name: "newsDetal",
    component: newsDetail,
    meta: {
      title: "新闻详情",
    },
  },

  {
    path: "/invitation",
    name: "invitation",
    component: invitation,
    children: [
      {
        path: "/cailist",
        component: Cailist,
        name: "cailist",
        meta: {
          title: "采购公告信息",
        },
      },

      {
        path: "/caitable",
        component: CaiTable,
        name: "CaiTable",
        meta: {
          title: "政府采购意向",
        },
      },
    ],

    meta: {
      title: "招投标公告",
    },
  },

  {
    path: "/listinfo",
    name: "listinfo",
    component: cailistDetal,
    meta: {
      title: "采购公告信息详情",
    },
  },
  {
    path: "/tableinfo",
    name: "caitableDetal",
    component: caitableDetal,
    meta: {
      title: "政府采购意向详情",
    },
  },

  {
    path: "/camodule",
    name: "CaModule",
    component: CaModule,
    meta: {
      title: "西部CA办理",
    },
  },
  {
    path: "/imgecourse",
    name: "ImgeCourse",
    component: ImgeCourse,
    meta: {
      title: "图文教程详情",
    },
  },
];
// title

const router = new VueRouter({
  routes,

  // 跳转页面后 回到顶部
  // scrollBehavior() {
  //   return {
  //     x: 0,
  //     y: 0,
  //   };
  // },
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  //   console.log(to, from, next);
  next();
});
