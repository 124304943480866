<template>
  <div class="kejipage_wrap">
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>服务项目</span></div>
    </div>
    <!-- 科技板块 -->
    <div class="TechnologySector">
      <div class="TechnologySector_cont">
        <h1>{{ info.serviceInfo.webContentTitle }}</h1>
        <div class="TechnologySector_cont_bottom">
          <div class="TechnologySector_cont_bottom_text">
            {{ info.serviceInfo.webContentText }}
          </div>
          <!-- <div class="TechnologySector_cont_bottom_text_english">
            TECHNOLOGY
          </div> -->
        </div>
      </div>
    </div>
    <!-- 教育 -->
    <div class="Education">
      <div class="Education_cont">
        <h1>{{ info.title.webContentTitle }}</h1>
        <div class="Education_item">
          <div
            class="Education_item1"
            v-for="(item, index) in serviceList"
            :key="index"
            @click="detailList(item.serviceType)"
          >
            <img :src="item.picLoad" alt="" />
            <div class="Education_item_text">
              <p>{{ item.serviceTitle }}</p>
              <span>{{ item.remark }}</span>
              <div class="more">查看更多列表&gt;&gt;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import article from "@/api/article";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "服务项目",
      Img1: require("../assets/images/newsbanner.jpg"),
      infoData: [],
      info: {
        title: {
          webContentText: "",
          webContentTitle: "",
        },
        serviceInfo: {
          webContentText: "",
          webContentTitle: "",
        },
      },
      serviceList: [],
    };
  },
  mounted() {
    this.handleGetData();
    this.serviceData();
  },
  methods: {
    serviceData() {
      article
        .serviceList()
        .then((res) => {
          this.serviceList = res.data.data.records;
          console.log(this.serviceList, "5555");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleGetData() {
      article
        .aboutData({ belongPage: "project" })
        .then((res) => {
          this.infoData = res.data.data.records;
          this.infoData.forEach((item) => (this.info[item.notes] = item));
          console.log(this.infoData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    detailList(serviceType) {
      this.$router.push({
        path: "/projectlist",
        query: { serviceType },
      });
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.kejipage_wrap {
  width: 100vw;
  margin: 0 auto;
  background: #fff;
  /* height: 1000px; */
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 科技板块 */
.TechnologySector_cont {
  width: 1200px;
  margin: 0 auto;
  padding-top: 64px;
}
.TechnologySector_cont h1 {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 32px;
  color: #3494ec;
  font-weight: bold;
  line-height: 45px;
  text-align: center;
}

.TechnologySector_cont_bottom_text {
  line-height: 35px;
  font-size: 16px;
  width: 960px;
  margin: 0 auto;
  z-index: 10;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #333333;
  padding: 30px;
  margin-bottom: 50px;
  text-indent: 2em;
  /* border-radius: 15px; */
  /* box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.3); */
}
/* 服务项目列表 */
.Education {
  width: 100vw;
  background: #f8f8f8;
}
.Education_cont {
  width: 1200px;
  margin: 0 auto;
  padding-top: 70px;
}
.Education_cont h1 {
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 32px;
  color: #3494ec;
  font-weight: bold;
  line-height: 45px;
}
.Education_item {
  margin-top: 45px;
}
.Education_item1 {
  display: flex;
}
.Education_item1 img {
  width: 460px;
  height: 340px;
}
.Education_item_text {
  background: #ffffff;
  width: 740px;
  height: 340px;
}
.Education_item_text p {
  margin-top: 72px;
  margin-left: 86px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3494ec;
  line-height: 33px;
}
.Education_item_text span {
  width: 568px;
  height: 140px;
  margin-top: 24px;
  margin-left: 86px;
  display: inline-block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  overflow: hidden; /* 超出部分隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 5; /* 设置显示两行文本 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
}
.Education_item_text .more {
  font-size: 14px;
  text-align: right;
  color: #444;
  margin-top: 10px;
  margin-right: 80px;
}
.Education_item1 {
  padding-bottom: 40px;
}
.Education_item1_block {
  width: 550px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 86px;
}

.Education_item1_block .div3 {
  margin: 40px 24px 38px 0;
  line-height: 40px;
  text-align: center;
  width: 172px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}
.Education_item1_block .div4 {
  margin-top: -10px;
}
.Education_item1_block .div5 {
  margin-top: -10px;
}

.Education_item1_block div {
  margin: 40px 24px 38px 0;
  line-height: 40px;
  text-align: center;
  width: 140px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}
</style>
