<template>
  <div class="nav">
    <div class="navbar">
      <div class="nav-meun">
        <router-link to="/" class="navtype">首页</router-link>
      </div>
      <div class="nav-meun">
        <router-link active-class="active" to="/invitation" class="navtype"
          >广西招投标公告</router-link
        >
      </div>
      <div class="nav-meun">
        <router-link active-class="active" to="/camodule" class="navtype"
          >西部CA办理入口</router-link
        >
      </div>
      <div class="nav-meun">
        <router-link active-class="active" to="/projectservices" class="navtype"
          >服务项目</router-link
        >
      </div>
      <div class="nav-meun">
        <router-link active-class="active" to="/AllNews" class="navtype"
          >新闻资讯</router-link
        >
      </div>
      <div class="nav-meun">
        <router-link active-class="active" to="/about" class="navtype"
          >关于我们</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      current: 0,
      navlist: [
        { title: "首页", path: "/" },
        { title: "广西招投标公告", path: "/invitation" },
        { title: "西部CA办理入口", path: "/camodule" },
        { title: "服务项目", path: "/projectservices" },
        { title: "新闻资讯", path: "/news" },
        // { title: "关于我们", path: "/jituangaikuo" },
      ],
      //   logoimg: require("../assets/img/lALPDhmOw39u8LZszQFq_362_108.png_720x720q90g.jpg"),
    };
  },
  methods: {
    funyuming(index, item) {
      this.current = index;
      this.$router.push(item);
      // console.log(this.navIndex);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
</script>
<style scoped lang="less">
// 导航样式
.nav {
  width: 100%;
  height: 70px;
  background-color: #3494ec;
  min-width: 1300px;
  //   position: absolute;
  //   bottom: 0;
  //   position: relative;
  .navbar {
    display: flex;
    color: #eeeded;
    font-size: 28px;
    font-weight: 700;
    // line-height: 70px;
    height: 70px;
    align-items: center;
    width: 1300px;
    margin: 0 auto;
    justify-content: space-evenly;
    // justify-content: center;
    .navtype {
      color: #fff;
      text-decoration: none;
    }
    .nav-meun {
      // padding: 0 10px;
      //   margin-right: 20px;
      // &.active {
      //   background-color: #40bcff;
      //   color: #fff;
      // }
    }
    .nav-meun:hover {
      // background-color: #40bcff;
      // color: #fff;
      // padding: 16px 20px;
      // text-decoration: none;
    }
  }
}
.active {
  background-color: #40bcff;
  padding: 16px 20px;
}
</style>
