<template>
  <div class="GroupOverview">
    <banner2 :bannerImg="Img1"></banner2>
    <daohang></daohang>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>关于我们</span></div>
    </div>
    <!-- 关于企业 -->
    <div class="about-bg">
      <div class="about" id="about">
        <div class="about-top">
          <div class="about-top-l">
            <span> {{ info.msg.webContentTitle }} </span>
            <p>{{ info.msg.webContentText }}</p>
          </div>
          <div class="about-top-r">
            <div class="img-yinying"></div>
            <img
              src="https://img.js.design/assets/img/613eaa7ba9696d53260506a9.png"
              alt=""
              width="400px"
              height="400px"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 上部分 -->
    <div class="talk_top">
      <div class="talk_top_cont">
        <div class="talk_top_cont_l">
          <h1>联系我们</h1>
          <p>contact us</p>
        </div>
        <div class="talk_top_cont_c">
          <div class="talk_top_cont_c_t">
            <div class="talk_top_cont_c_t_l">
              <p>{{ info.phone.webContentTitle }}</p>
              <p>{{ info.phone.webContentText }}</p>
            </div>
            <div class="talk_top_cont_c_t_r">
              <p>{{ info.mobile.webContentTitle }}</p>
              <p>
                <span>{{ info.mobile.webContentText }}</span>
                <span>{{ info.mobile2.webContentText }}</span>
              </p>
            </div>
          </div>
          <div class="talk_top_cont_c_b_r">
            <p>
              {{ info.address.webContentTitle
              }}{{ info.address.webContentText }}
            </p>
            <p>
              {{ info.address2.webContentTitle
              }}{{ info.address2.webContentText }}
            </p>
          </div>
        </div>
        <div
          class="talk_top_cont_r"
          style="text-align: center"
          v-for="(item, index) in imgList"
          :key="index"
        >
          <img width="100px" height="100px" :src="item.picLoad" />
          <span style="font-size: 12px; text-align: center">{{
            item.title
          }}</span>
        </div>
      </div>
    </div>
    <!-- 下部分 -->
    <div
      class="talk_bottom"
      style="display: flex; justify-content: space-between"
    >
      <!-- <img src="../assets/img/lianxi/map.jpg" alt="" /> -->
      <Mapview style="width: 48%"></Mapview>
      <Mapview2 style="width: 48%"></Mapview2>
    </div>
    <service></service>
    <footer2></footer2>
  </div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import article from "@/api/article";
import service from "@/components/service.vue";
import Mapview from "@/components/MapContainer.vue";
import Mapview2 from "@/components/MapContainers.vue";

export default {
  name: "App",
  data() {
    return {
      h1: "关于我们",
      P1: "欢迎加入我们",
      Img1: require("../assets/images/bannerabout.png"),
      pageNum: 1,
      pageSize: 10,
      infoData: [],
      //   companyInfo: [
      //     {
      //       webContentText: "",
      //       webContentTitle: "",
      //     },
      //   ],
      total: 100,
      size: 10,
      current: 1,
      imgList: [],
      info: {
        address: {
          webContentText: "",
          webContentTitle: "",
        },
        address2: {
          webContentText: "",
          webContentTitle: "",
        },
        phone: {
          webContentText: "",
          webContentTitle: "",
        },
        msg: {
          webContentText: "",
          webContentTitle: "",
        },
        mobile: {
          webContentText: "",
          webContentTitle: "",
        },
        mobile2: {
          webContentText: "",
          webContentTitle: "",
        },
      },
    };
  },
  components: {
    daohang,
    footer2,
    banner2,
    service,
    Mapview,
    Mapview2,
  },
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
    this.handleGetData();
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      article.bannerList({ belongPage: "foot" }).then((res) => {
        this.imgList = res.data.data.records.slice(2, 3);
      });
    },
    handleGetData() {
      this.loading = true;
      //   let params = {
      //     size: this.size,
      //     current: this.current,
      //   };
      article
        .aboutData({ belongPage: "about" })
        .then((res) => {
          this.infoData = res.data.data.records;
          this.infoData.forEach((item) => (this.info[item.notes] = item));
          // console.log(this.info);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },
  },
};
</script>

<style scoped>
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
.GroupOverview {
  width: 100vw;
  margin: 0 auto;
}
.about {
  height: 500px;
  position: relative;
  /* margin: 70px 200px; */
  width: 1300px;
  margin: 0 auto;
}

.about-bg {
  background-image: url("../assets/img/蒙版.png");
  background-size: cover;
  background-position: center;
}

.about-top {
  position: relative;
  display: flex;
  justify-content: space-around;
}

.about-top-l {
  position: absolute;
  left: 20px;
  top: 85px;
  width: 611px;
  height: 442px;
}

.about-top-l > span {
  font-size: 32px;
  color: #3494ec;
  font-weight: bold;
}

.about-top-l > p {
  font-size: 16px;
  line-height: 40px;
}

.img-yinying {
  position: absolute;
  top: 58px;
  left: -17px;
  width: 17px;
  height: 408px;
  background: #f8f8f8;
}

.about-top-r {
  position: absolute;
  right: 30px;
}

.about-top-r img {
  width: 487px;
  height: 517px;
}

.talk_top_cont {
  display: flex;
  justify-content: space-around;
  width: 1300px;
  padding-top: 90px;

  margin: 0 auto;
}
.talk_top_cont_l {
  width: 235px;
  height: 134px;
}
.talk_top_cont_l > h1 {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3494ec;
  line-height: 45px;
}
.talk_top_cont_l p {
  width: 235px;
  height: 80px;
  font-size: 48px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f6f6f6;
  line-height: 40px;
}
.talk_top_cont_c {
  /* display: flex; */
  width: 640px;
}
.talk_top_cont_c {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 25px;
}

.talk_top_cont_c_t_l {
  margin-right: 60px;
  width: 300px;
}
.talk_top_cont_c_t {
  display: flex;
}
.talk_top_cont_r {
  width: 117px;
  /* display: flex; */
}
.talk_top_cont_r img {
  border: dashed;
  display: block;
  /* width: 100%; */
}
.talk_top_cont_c_b_r {
  margin-top: 36px;
}
.talk_top_cont_c_b_r p {
  margin-top: 20px;
}
.talk_bottom {
  width: 1300px;
  margin: 91px auto;
  margin-bottom: 81px;
  /* display: flex; */
}
</style>
