<template>
  <!-- 新闻内容 -->
  <div class="news_cont_zong">
    <div class="item2">
      <div class="item2_cont">
        <div
          v-for="(item, index) in newsList"
          :key="index"
          @click="funUrl(item.id)"
        >
          <div
            class="item2_cont1"
            v-if="item.newType == 2"
            style="cursor: pointer"
          >
            <img :src="item.picLoad" alt="" />
            <div class="item2_cont1_text">
              <div class="item2_cont1_text_top">
                <h1>
                  {{ item.newsTitle }}
                </h1>
                <p>
                  {{ item.remark }}
                </p>
              </div>
              <div class="item2_cont1_text_btm">
                <div>{{ item.createTime }}</div>
                <p>查看更多&gt;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import article from "@/api/article";
export default {
  data() {
    return {
      newsList: [],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    funUrl(id) {
      this.$router.push({
        path: "/newsdetal",
        query: { id },
      });
    },
    getList() {
      this.loading = true;
      let params = { newType: 1 };
      article
        .newsList(params)
        .then((res) => {
          this.newsList = res.data.data.records;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* 新闻列表 */
.item_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.item2_cont1:first-child {
  display: flex;
  width: 1200px;
  height: 252px;
  background: #ffffff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  /* border: dashed; */
}
.item_cont1_text {
  width: 868px;
}
.item_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3494ec;
  line-height: 24px;
}
.item_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3494ec;
  line-height: 28px;
}
.item_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3494ec;
  line-height: 28px;
}
/* item2 */
.item2_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
}
.item2_cont1 {
  margin-top: 30px;
  display: flex;
  width: 1200px;
  height: 252px;

  background: #f8f8f8;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item2_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  /* border: dashed; */
}
.item2_cont1_text {
  width: 868px;
}
.item2_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item2_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  color: #3f3f3f;
  line-height: 24px;
}
.item2_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  max-height: 3em; /* 设置最大高度为三行 */
  overflow: hidden; /* 超出部分隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置显示两行文本 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
}
.item2_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item2_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  color: #999999;
  line-height: 28px;
}
.item2_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3494ec;
  line-height: 28px;
}
</style>
