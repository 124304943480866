import http from "@/axios";
import { stringify } from "qs";
// import post from "@/axios";

const article = {
  //首页招投标公告、采购公告信息
  getList: (params) =>
    http.get("/api/blade-web/ordinaryProcure/ordinaryList", { params }),
  //通知公告
  noticeList: (params) =>
    http.get("/api/blade-web/home/noticeList", stringify(params)),
  //通知公告详情
  getNoticeDetail: (params) =>
    http.get("/api/blade-web/home/noticeDetail", { params }),
  //图文教程
  imgdata: (params) =>
    http.get("/api/blade-web/home/manualList", stringify(params)),
  //图文教程详情
  getImgDetail: (params) =>
    http.get("/api/blade-web/home/manualDetail", { params }),

  //视频教程
  videodata: (params) =>
    http.get("/api/blade-web/home/videoList", {params}),
  //政府采购意向
  getcaiTable: (params) =>
    http.get("/api/blade-web/ordinaryProcure/govList", { params }),

  //采购公告信息详情
  getDetail: (params) =>
    http.get("/api/blade-web/ordinaryProcure/ordinaryDetail", { params }),
  //采购公告信息详情
  getcaiTableDetail: (params) =>
    http.get("/api/blade-web/ordinaryProcure/govDetail", { params }),

  //新闻资讯列表
  newsList: (params) => http.get("/api/blade-web/home/newList", {params}),
  //新闻资讯详情
  newsDetail: (params) => http.get("/api/blade-web/home/newDetail", { params }),
  //关于我们
  aboutData: (params) => http.get("/api/blade-web/home/neirongList", { params }),
  //下载资料
  attchList: (params) => http.get("/api/blade-web/home/attchList", { params }),
  //新闻资讯列表
  bannerList: (params) => http.get("/api/blade-web/home/pictureList", { params }),
  searchList: (params) => http.get("/api/blade-web/home/homeSearch", { params }),
  serviceList: (params) => http.get("/api/blade-web/home/serviceList", { params }),
  productList: (params) => http.get("/api/blade-web/home/productList", { params }),
  serviceDetail: (params) =>
    http.get("/api/blade-web/home/productDetail", {
      params,
    }),
  serviceProductList: (params) =>
    http.get("/api/blade-web/home/serviceProductList", {
      params,
    }),
  // 浏览记录 
  browseRecord: (params) => http({
    url:"/api/blade-dataAnalysis/dataAnalysis/submit",
    headers:{
      'Content-Type': 'application/json', // 指定内容类型为JSON
    },
    method: 'post', 
    data:JSON.stringify(params),

  }),
  commonProblemList: (params) => http.get("/api/blade-web/commonProblem/list", { params }),
};

export default article;
