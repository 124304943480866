<!-- star -->
<template>
  <div class="youjiaopage_wrap">
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>西部CA办理入口</span></div>
    </div>
    <div class="main">
      <div class="line">
        <div class="wrapper">
          <div
            class="box-area"
            v-for="(item, index) in navList"
            :key="index"
            @click="getNav(item)"
          >
            <div class="box-content">
              <a :href="item.href">
                <h2 :class="[item.active == true ? 'active' : '']">
                  {{ item.name }}
                </h2>
              </a>
              <span
                class="trigon"
                :class="[item.active == true ? 'current' : '']"
              ></span>
              <span
                class="dot"
                :class="[item.active == true ? 'alldot' : '']"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="contain">
          <div class="talk_top_cont_l" id="section1">
            <h1>{{ info.CA.webContentTitle }}</h1>
          </div>
          <p class="">
            {{ info.CA.webContentText }}
          </p>
        </div>
        <div class="contain_2">
          <div class="talk_top_cont_l" id="section2">
            <h1>CA锁办理流程</h1>
          </div>
          <div class="box">
            <div
              v-for="(item, index) in newInfoList"
              :key="index"
              style="display: flex; align-items: center"
            >
              <div class="box-item">
                <h2>{{ item.webContentTitle }}</h2>
                <span>
                  {{ item.webContentText }}
                </span>
              </div>
              <p class="aroww">&rarr;</p>
            </div>
          </div>
        </div>
        <div class="video-course">
          <div class="talk_top_cont_l" id="section3">
            <h1>视频教程</h1>
          </div>
          <div class="video-main">
            <div
              class="notice"
              v-for="(item, index) in videoList"
              :key="index"
              @click="getvideo(item.url)"
            >
              <div class="pic">
                <img :src="item.picLoad" />
              </div>
              <div class="title">
                <span>{{ item.tittle }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="img-course">
          <div class="talk_top_cont_l" id="section4">
            <h1>图文教程 & 资料下载</h1>
          </div>
          <div class="img-main">
            <el-tabs type="border-card">
              <el-tab-pane label="图文教程">
                <div class="img-main">
                  <ul
                    v-for="(item, index) in GraphicList"
                    :key="index"
                    @click="getDetail(item.id)"
                    style="cursor: pointer"
                  >
                    <li>
                      <div class="content">
                        <div class="dot"></div>
                        <span class="title">{{ item.tittle }}</span>
                      </div>
                      <span class="time">{{ item.createTime }}</span>
                      <el-button type="primary">查看详情</el-button>
                    </li>
                  </ul>
                </div>
              </el-tab-pane>
              <el-tab-pane label="资料下载">
                <div class="dowload-main">
                  <ul v-for="(item, index) in downList" :key="index">
                    <li>
                      <div class="content">
                        <div class="dot"></div>
                        <span class="title">{{ item.originalName }}</span>
                      </div>
                      <el-button type="primary" @click="downloadFile(item.link)"
                        >下载</el-button
                      >
                    </li>
                  </ul>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <div class="img-course">
          <div class="talk_top_cont_l" id="section5">
            <h1>常见问题</h1>
          </div>
          <div
            class="question_list"
            v-for="(item, index) in problemList"
            :key="index"
          >
            <div class="question_item">
              <!-- <div class="content">
                <div class="dot"></div>
                <span class="title">{{ item.question }}</span>
              </div> -->
              <div class="question_title">
                <div class="question_icon"></div>
                {{ item.question }}
              </div>
              <div class="question_text">答：{{ item.answer }}</div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        width="80%"
        :visible.sync="dialogVisible"
        destroy-on-close
        center
      >
        <div style="display: flex; justify-content: center">
          <video width="1000" controls>
            <source :src="videoUrl" type="video/mp4" />
          </video>
        </div>
      </el-dialog>
    </div>
    <service></service>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import service from "@/components/service.vue";
import article from "@/api/article";
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
export default {
  name: "CaModule",
  components: {
    footer2,
    nav2,
    banner2,
    service,
  },
  data() {
    return {
      dialogVisible: false,
      videoUrl: "",
      h1: "CA服务",
      Img1: require("../../assets/images/newsbanner.jpg"),
      p1: "更多CA项目",
      navIndex: 0,
      navList: [
        { name: "什么是CA锁", active: true, id: "section1" },
        { name: "办理流程", active: false, id: "section2" },
        { name: "视频教程", active: false, id: "section3" },
        { name: "图文教程", active: false, id: "section4" },
        { name: "资料下载", active: false, id: "section4" },
        { name: "常见问题", active: false, id: "section5" },
      ],
      newInfoList: [],
      videoList: [],
      GraphicList: [],
      downList: [],
      problemList: [],
      infoData: [
        {
          webContentText: "",
          webContentTitle: "",
        },
      ],
      info: {
        CA: {
          webContentText: "",
          webContentTitle: "",
        },
        CAinfo1: {
          webContentText: "",
          webContentTitle: "",
        },
        CAinfo2: {
          webContentText: "",
          webContentTitle: "",
        },
        CAinfo3: {
          webContentText: "",
          webContentTitle: "",
        },
        CAinfo4: {
          webContentText: "",
          webContentTitle: "",
        },
        CAinfo5: {
          webContentText: "",
          webContentTitle: "",
        },
      },
      reverse: true,
    };
  },
  mounted() {
    this.handleCAdata();
    this.handleVideoData();
    this.handleImgData();
    this.downloadList();
  },
  methods: {
    getNav(item) {
      this.navList.forEach((item) => {
        item.active = false;
      });
      item.active = true;
      this.$router.push({ hash: `#${item.id}` });
    },
    handleCAdata() {
      article.aboutData({ belongPage: "CA" }).then((res) => {
        this.infoData = res.data.data.records;
        this.infoData.forEach((item) => (this.info[item.notes] = item));
        this.newInfoList = this.infoData.filter((item) => {
          if (item.notes != "CA") {
            return item;
          }
        });
        console.log(this.newInfoList, "444");
        setTimeout(() => {
          this.removeElement();
        }, 100);
      });
    },
    handleImgData() {
      article.imgdata().then((res) => {
        this.GraphicList = res.data.data.records;
      });
    },
    // 获取常见问题
    handleImgData() {
      article.commonProblemList().then((res) => {
        console.log("常见问题", res);
        this.problemList = res.data.data.records;
      });
    },
    getDetail(id) {
      this.$router.push({
        path: "/imgecourse",
        query: { id },
      });
    },
    getvideo(url) {
      // window.open(url, "_blank");
      this.dialogVisible = true;
      this.videoUrl = url;
      // this.videoUrl = url
      console.log("444", url);
    },
    handleVideoData() {
      article.videodata().then((res) => {
        this.videoList = res.data.data.records;
      });
    },
    downloadList() {
      article.attchList().then((res) => {
        console.log(res.data);
        this.downList = res.data.data.records;
      });
    },
    downloadFile(fileName) {
      window.open(fileName);
    },
    removeElement() {
      document.querySelector(".box").lastChild.lastChild.remove();
    },
  },
};
</script>
<style scoped lang="less">
.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

.main {
  display: flex;
  width: 1300px;
  margin: 0 auto;
  justify-content: space-between;
}
.line {
  height: 300px;
  width: 200px;
}
.wrapper {
  font-family: roboto;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  border-right: 2px solid #3494ec;
  position: relative;
  height: 70vw;
}

.box-area {
  margin: 30px 0;
}
.box-content {
  position: absolute;
  right: -14px;
  display: flex;
  align-items: center;
}
.box-content h2 {
  margin: 0;
  font-size: 14px;
  // background: #3494ec;
  padding: 12px 20px;
  white-space: nowrap;
}
.active {
  background: #3494ec;
  color: #fff;
}
.current {
  width: 0;
  height: 0;
  border-top: 22.5px solid transparent;
  border-left: 20px solid #3494ec;
  border-bottom: 22.5px solid transparent;
}
.alldot {
  background: #3494ec !important;
}

.box-content .dot {
  font-size: 20px;
  padding: 10px;
  margin: auto;
  background: #fff;
  border: 3px solid #3494ec;
  border-radius: 50%;
  margin-left: 33px;
}

.right-content {
  width: 960px;
  display: flex;
  flex-direction: column;
}
.contain_2 {
  width: 100%;
  margin: 0 auto;
  .box {
    display: inline-flex;
    flex-wrap: wrap;
    // width: 1100px;
    margin: 0 auto;
    align-items: center;
    .box-item {
      border: 1px solid #3494ec;
      border-radius: 15px;
      width: 200px;
      height: 120px;
      margin: 20px 0;
      margin-right: 20px;
      padding: 20px 14px;
      background: #3494ec;
      color: #fff;
      h2 {
        text-align: center;
        padding-bottom: 10px;
      }
      span {
        max-height: 5em; /* 设置最大高度为三行 */
        overflow: hidden; /* 超出部分隐藏 */
        display: -webkit-box;
        -webkit-line-clamp: 5; /* 设置显示两行文本 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
      }
    }
    .aroww {
      color: #3494ec;
      font-size: 50px;
      font-weight: 900;
      margin-right: 20px;
    }
    // .aroww:last-child {
    //   display: none;
    // }
  }
}
.talk_top_cont_l {
  // height: 100px;
  // line-height: 100px;
}
.talk_top_cont_l > h1 {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3494ec;
  line-height: 100px;
  text-align: center;
  // margin: 20px 0;
}
.video-course {
  margin-bottom: 20px;
  width: 100%;
}
.video-main {
  // width: 1300px;
  // width: 1100px;
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin: 0 auto;
  justify-content: center;
}
.video-main .notice {
  margin: 0 auto;
  border-radius: 15px;
  border: 1px solid #696a6b;
  cursor: pointer;
}
.video-main .pic {
  border-radius: 15px 15px 0 0;
  width: 300px;
  height: 150px;
}
.video-main .pic img {
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 0 0;
}
.video-main .title {
  text-align: center;
  padding-top: 10px;
  color: #333;
  margin: 10px 0 20px 0;
}
.img-course {
  width: 100%;
  // width: 100vw;
  margin: 0 auto;
  padding: 20px 0;
}
.img-main {
  // width: 100vw;
}
.img-course ul li,
.dowload-main ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.content {
  display: flex;
  align-items: center;
}
.content .dot {
  /* display: inline; */
  width: 10px;
  height: 10px;
  background: #3494ec;
  margin-right: 10px;
}
.more {
  text-align: right;
  color: #3494ec;
  font-size: 14px;
  margin-bottom: 20px;
}
.question_list {
  padding-bottom: 15px;
}
.question_title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 6px;
}
.question_icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #3494ec;
  margin-right: 10px;
}
.question_text {
  padding: 10px;
  border-radius: 5px;
  background: #efefef;
  font-size: 13px;
}
</style>
