<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>搜索</span></div>
    </div>
    <!-- 电商板块 -->
    <div class="Electricity">
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        style="
          width: 800px;
          margin: 0 auto;
          margin-top: 30px;
          margin-bottom: 30px;
        "
      >
        <el-input
          placeholder="请输入搜索信息"
          v-model="keyworder"
          class="input-with-select"
          clearable
        >
          <el-button
            type="primary"
            slot="append"
            size="medium"
            icon="el-icon-search"
            class="searchBtn"
            @click="getSeach"
            >搜索</el-button
          >
        </el-input>
      </el-form>
      <div class="meun">
        <el-tabs v-model="activeName">
          <el-tab-pane label="全部模块" name="first">
            <div class="box">
              <div
                class="box-conten"
                v-for="(item, index) in searchData"
                :key="index"
                @click="toTager(item)"
              >
                <div v-html="item.title" class="title"></div>
                <div v-html="item.content" class="content"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="采购公告信息" name="second">
            <div class="box">
              <div
                class="box-conten"
                v-for="(item, index) in ordinarysList"
                :key="index"
                @click="toTager(item)"
              >
                <div v-html="item.title" class="title"></div>
                <div v-html="item.content" class="content"></div>
                <div class="time">{{ item.publishDate }}</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="政府采购意向" name="three">
            <div class="box">
              <div
                class="box-conten"
                v-for="(item, index) in govOrdinarysList"
                :key="index"
                @click="toTager(item)"
              >
                <div v-html="item.title" class="title"></div>
                <div v-html="item.content" class="content"></div>
                <div class="time">{{ item.publishDate }}</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="新闻资讯" name="four">
            <div class="box">
              <div
                class="box-conten"
                v-for="(item, index) in newsList"
                :key="index"
                @click="toTager(item)"
              >
                <div v-html="item.title" class="title"></div>
                <div class="content">{{ item.content }}</div>
                <!-- <div v-html="item.newsContent" class="content"></div> -->
                <div class="time">{{ item.updateTime }}</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="图文信息" name="five">
            <div class="box">
              <div
                class="box-conten"
                v-for="(item, index) in manualsList"
                :key="index"
                @click="toTager(item)"
              >
                <div v-html="item.title" class="title"></div>
                <div v-html="item.content" class="content"></div>
                <div class="time">{{ item.updateTime }}</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="通知公告" name="six">
            <div class="box">
              <div
                class="box-conten"
                v-for="(item, index) in noticeList"
                :key="index"
                @click="toTager(item)"
              >
                <div v-if="noticeList.length">
                  <div v-html="item.title" class="title"></div>
                  <div v-html="item.content" class="content"></div>
                  <div class="time">{{ item.updateTime }}</div>
                </div>
              </div>
              <div v-if="!noticeList.length">暂无搜索内容</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      
    </div>
    <footer></footer>
    <!-- end -->
  </div>
</template>

<script>
import article from "@/api/article";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banners.vue";
import nav2 from "../components/nav.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "搜索平台",
      Img1: require("@/assets/images/banner1.png"),
      form: {},
      activeName: "first",
      title: "",
      pagination: {
        total: 0,
        size: 10,
        current: 1,
      },
      allData: {}, // 全部数据
      searchData: [],
      ordinarysList: [],
      govOrdinarysList: [],
      newsList: [],
      noticeList: [],
      manualsList: [],
      caigoulistList: [],
      caigoutableList: [],
      keyworder: "",
      infoParams: "",
    };
  },

  created() {
    if (this.$route.query) {
      this.infoParams = this.$route;
      let query_key_like = this.$route.params.name;
      this.getsearchList(query_key_like);
    }
  },
  methods: {
    /**
     * 搜索前端过滤
     */
    getSeach() {
      this.getsearchList(this.keyworder);
    },
    getsearchList(keyworder) {
      let params = {
        size: this.pagination.size,
        current: this.pagination.current,
        query_key_like: this.keyworder,
      };
      article.searchList(params).then(
        (res) => {
          console.log("res", res);
          var data = res.data.data;
          let govOrdinarysList = this.setDataList(data.govOrdinarys,"govOrdinarys",keyworder)
          let manualsList = this.setDataList(data.manuals,"manuals",keyworder)
          let newsList = this.setDataList(data.news,"news",keyworder)
          let noticeList = this.setDataList(data.notices,"notices",keyworder)
          let ordinarysList = this.setDataList(data.ordinarys,"ordinarys",keyworder)

          this.govOrdinarysList=govOrdinarysList
          this.manualsList=manualsList
          this.newsList=newsList
          this.noticeList=noticeList
          this.ordinarysList=ordinarysList
          this.searchData = [...ordinarysList, ...govOrdinarysList, ...newsList, ...manualsList,  ...noticeList]
         
        },
        (error) => {
          // loading();
          window.console.log(error);
        }
      );
    },
    /**
     * 搜索时匹配并高亮内容
     */
    setDataList(list,key,keyWord) {
      return list.map(item => {
        if(key == 'manuals') {
          item.title = item.tittle
          item.content = item.message
        }else if(key == 'news') {
          item.title = item.newsTitle
          item.content = item.remark
        }
        // 高亮颜色
        if(keyWord && item.title.includes(keyWord)) {
          let title = item.title.replace(new RegExp(keyWord, "g"), `<span style="color:#f84c21;">${keyWord}</span>`);
          item.title = title
          let content = item.content.replace(new RegExp(keyWord, "g"), `<span style="color:#f84c21;">${keyWord}</span>`);
          item.content = content
        }
        item.infoType = key
        return item
      })
    },
    toTager(row) {
      var id = row.id;
      if (row.articleId) {
        var articleId = row.articleId;
      }
      switch (row.infoType) {
        case "govOrdinarys": {
          //跳转页面
          // var articleId = row.articleId;
          // this.$router.push({
          //   path: "/tableinfo",
          //   query: { articleId },
          // });
          let routeData = this.$router.resolve({
            path: "/tableinfo",
            query: { articleId },
          });
          window.open(routeData.href, "_blank");
          break;
        }
        case "manuals": {
          let routeData = this.$router.resolve({
            path: "/imgecourse",
            query: { id },
          });
          window.open(routeData.href, "_blank");
          break;
        }
        case "news": {
          let routeData = this.$router.resolve({
            path: "/newsdetal",
            query: { id },
          });
          window.open(routeData.href, "_blank");
          break;
        }
        case "notices": {
          let routeData = this.$router.resolve({
            path: "/noticedetail",
            query: { id },
          });
          window.open(routeData.href, "_blank");
          break;
        }
        case "ordinarys": {
          let routeData = this.$router.resolve({
            path: "/listinfo",
            query: { articleId },
          });
          window.open(routeData.href, "_blank");
          break;
        }
      }
    },
  },
};
</script>
<style scoped lang="less">
.page {
  padding: 15px;
  margin: auto;
  text-align: center;
}

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  // height: 1200px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}

.Electricity {
  width: 1200px;
  margin: 0 auto;
  // height: 600px;
}
// .box :hover {
//   background: #e4e7ed;
// }
.box-conten {
  border-bottom: 1px solid #666;
  cursor: pointer;
  padding: 20px;

  .title {
    color: #3494ec;
    font-weight: 600;
    font-size: 20px;
    padding: 16px 0;
  }
  .content {
    margin-bottom: 20px;
    height: 90px;
    color: #444;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 24px;
    overflow: hidden; /* 超出部分隐藏 */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 设置显示两行文本 */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  }
  .time {
    font-size: 14px;
    color: #f88644;
  }
}
</style>
