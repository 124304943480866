<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>项目服务列表</span></div>
    </div>

    <div class="container">
      <h1>项目服务列表</h1>
      <div class="item2">
        <div class="item2_cont">
          <div
            v-for="(item, index) in productList"
            :key="index"
            @click="getnoticeDetail(item.id)"
            style="cursor: pointer"
          >
            <div class="item2_cont1">
              <img :src="item.imagesUrl" alt="" />
              <div class="item2_cont1_text">
                <div class="item2_cont1_text_top">
                  <h1>
                    {{ item.name }}
                  </h1>
                  <p>
                    {{ item.notes }}
                  </p>
                </div>
                <div class="item2_cont1_text_btm">
                  <!-- <div>{{ item.createTime }}</div> -->
                  <p>查看更多&gt;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import article from "@/api/article";
export default {
  name: "noticelis",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      Img1: require("../assets/images/banner1.png"),
      productList: [],
    };
  },
  created() {
    if (this.$route.query) {
      this.infoParams = this.$route.query;
      // console.log("infoParams", this.infoParams);
      let serviceType = this.$route.query.serviceType;
      this.handleGetData(serviceType);
    }
  },
  mounted() {},
  methods: {
    handleGetData(serviceType) {
      article
        .productList({ serviceType: serviceType })
        .then((res) => {
          this.productList = res.data.data.records;
          console.log(this.productList, "5555");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getnoticeDetail(id) {
      var url = this.$router.resolve({
        path: "/projectdetail",
        query: { id },
      }).href;
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped lang="less">
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 幼儿板块 */
.container {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.container > h1 {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #3494ec;
  line-height: 45px;
  text-align: center;
  margin-top: 40px;
  // border-bottom: 1px solid #666;
}
.item2_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
}
.item2_cont1 {
  margin-top: 30px;
  display: flex;
  width: 1200px;
  height: 252px;

  background: #f8f8f8;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item2_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  /* border: dashed; */
}
.item2_cont1_text {
  width: 868px;
}
.item2_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item2_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  color: #3f3f3f;
  line-height: 24px;
}
.item2_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  max-height: 3em; /* 设置最大高度为三行 */
  overflow: hidden; /* 超出部分隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置显示两行文本 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
}
.item2_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item2_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  color: #999999;
  line-height: 28px;
}
.item2_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
</style>
