<template>
  <div class="container">
    <div class="top">
      <!-- 轮播图 -->
      <BannerScroll></BannerScroll>
      <!-- 导航 -->
      <Nav></Nav>
    </div>

    <div class="main">
      <!-- 最新招投标公告 -->
      <div class="main-center">
        <div class="notice-info notice bgbiue">
          <div class="notice-header">
            <div class="notice-title">广西招投标最新项目信息</div>
            <div class="more">
              <router-link
                to="/invitation"
                style="color: #fff; text-decoration: none"
                >more &gt;&gt;</router-link
              >
            </div>
          </div>
          <div class="notice-content">
            <div
              v-for="(item, index) in noticelist"
              :key="index"
              @click="goInfo(item)"
              class="notice-item"
            >
              <div class="title">
                <span class="cirle"> </span>
                <div class="titlename">{{ item.title }}</div>
              </div>
              <div class="dataTime">{{ item.publishDate }}</div>
            </div>
          </div>
        </div>
        <!-- CA入口 -->
        <div class="main-right">
          <div class="CA-info" @click="getCA">
            <div class="CA-main">
              <img src="./../assets/images/lock2.png" />
            </div>
            <div class="CA-title">西部CA<br />办理入口</div>
          </div>
          <!--通知 公告 -->
          <div class="notice margin-top bgbiue">
            <div class="notice-header box">
              <div class="notice-title">
                <span style="padding-left: 50px">通知公告</span>

                <i class="el-icon-bell"></i>
              </div>
              <div class="more">
                <router-link
                  to="/noticelist"
                  style="text-decoration: none; color: #333"
                  >more &gt;&gt;</router-link
                >
              </div>
            </div>
            <div class="notice-content">
              <div
                v-for="(item, index) in infolist"
                :key="index"
                @click="getnoticeDetail(item.id)"
                class="notice-item"
              >
                <div class="title">
                  <span class="cirle"> </span>
                  <div class="titlename">{{ item.title }}</div>
                </div>
                <div class="dataTime">{{ item.releaseTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 更多服务 -->
      <div class="service">
        <div class="service-title">
          <div class="en">MORE SERVICES</div>
          <div class="cn">更多服务</div>
          <div class="line"></div>
        </div>
      </div>

      <div class="service-main">
        <div
          class="notice margin-top bgblack"
          v-for="item in productTypeList"
          :key="item.service.id"
        >
          <div
            @click="getService(item.service.serviceType)"
            class="notice-header notice-img"
            :style="`background-image:url(${item.service.picLoad});`"
          >
            <div class="bg">
              <div class="notice-title notice-name">
                {{ item.service.serviceTitle }}
              </div>
              <div class="serviceMore">more &gt;&gt;</div>
            </div>
          </div>
          <div
            class="notice-content"
            style="padding: 5px 20px"
            v-for="(arr, index) in item.products"
            :key="index"
            @click="getProductDetail(arr.id)"
          >
            <div class="notice-item">
              <div class="title">
                <span class="cirle"> </span>
                <div class="titleservice">
                  <div class="titlename">
                    {{ arr.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <service></service>
    </div>

    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import article from "@/api/article";
import BannerScroll from "./../components/bannerScroll.vue";
import Nav from "./../components/nav.vue";
import Footer from "./../components/footer.vue";
import service from "../components/service.vue";
export default {
  name: "homeIndex",
  components: {
    Nav,
    Footer,
    BannerScroll,
    service,
  },
  data() {
    return {
      noticelist: [],
      infolist: [],
      productTypeList: [],
    };
  },
  mounted() {
    this.handleGetData();
    this.getInfolist();
    this.serviceData();
  },
  methods: {
    handleGetData() {
      article
        .getList()
        .then((res) => {
          // console.log(res.data.data);
          this.noticelist = res.data.data.records.slice(0, 10);
          this.noticelist.forEach((item) => {
            item.publishDate = item.publishDate.slice(0, 11);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goInfo(item) {
      this.$router.push({
        path: "/listinfo",
        query: { articleId:item.articleId },
      });
    },
    getInfolist() {
      article
        .noticeList()
        .then((res) => {
          this.infolist = res.data.data.records.slice(0, 5);
          this.infolist.forEach((item) => {
            item.releaseTime = item.releaseTime.slice(0, 11);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    serviceData() {
      article
        .serviceProductList()
        .then((res) => {
          this.productTypeList = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getnoticeDetail(id) {
      this.$router.push({
        path: "/noticedetail",
        query: { id },
      });
    },
    funyuming(msg) {
      this.$router.push(msg);
    },
    getService(serviceType) {
      this.$router.push({
        path: "/projectlist",
        query: { serviceType },
      });
    },
    getProductDetail(id) {
      this.$router.push({
        path: "/projectdetail",
        query: { id },
      });
    },
    getCA() {
      this.$router.push({
        path: "/camodule",
      });
    },

    formatData(arr) {
      const resultArr = arr.reduce((acc, item) => {
        const existItem = acc.find((i) => i.serviceType === item.serviceType);
        if (existItem) {
          existItem.list.push({ name: item.name });
        } else {
          acc.push({
            serviceType: item.serviceType,
            list: [{ name: item.name }],
          });
        }
        return acc;
      }, []);

      return resultArr;
    },
  },
};
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.container {
  /* width: 100%; */
  .top {
    width: 100%;
    height: 470px;
    position: relative;
    left: 0;
    top: 0;
  }
}

.main {
  width: 1300px;
  margin: 0 auto;
}
// img {
//   width: 100%;
//   height: 300px;
// }

// 公告样式
.main-center {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 60px;
  .notice-info {
    width: 60%;
    .notice-header {
      background-color: #3494ec;
      border-radius: 25px 25px 0 0;
      padding: 10px;
      padding-left: 50px;
      color: #fff;
      height: 40px;
      line-height: 40px;

      .notice-title {
        color: #fff;
        font-size: 26px;
      }
    }

    .notice-content {
      padding: 20px 40px;
      .notice-item {
        padding: 16px 0;
        cursor: pointer;
        .title {
          .titlename {
            width: 450px;
          }
          .cirle {
            background-color: #3494ec;
            border-radius: 50%;
          }
        }
        .dataTime {
          font-size: 16px;
          color: #222121;
          // width: 20%;
        }
      }
    }
  }
  .main-right {
    width: 30%;
    // CA信息
    .CA-info {
      height: 180px;
      border-radius: 25px;
      background-color: #3494ec;
      background-image: url("./../assets/images/line.png");
      opacity: 0.8;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      //   margin-left: 30px;
      padding: 40px;
      display: flex;
      align-items: center;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      .CA-main {
        img {
          width: 80px;
          height: 80px;
        }
      }
      .CA-title {
        font-size: 30px;
        color: #fff;
        font-weight: 600;
        margin-left: 30px;
      }
    }
  }
}

//更多服务
.service {
  .service-title {
    text-align: center;
    height: 120px;
    margin-top: 50px;
    .en {
      font-weight: 600;
      font-size: 48px;
      text-transform: uppercase;
      opacity: 0.1;
      background: linear-gradient(to top, #555555, #ffffff);
      background-clip: text;
      color: transparent;
    }
    .cn {
      font-size: 36px;
      font-weight: 600;
      color: #3494ec;
      transform: translateY(-30px);
    }
    .line {
      width: 100px;
      height: 5px;
      background: #3494ec;
      display: inline-block;
      transform: translateY(-20px);
    }
  }
}

.service-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  .notice-img {
    background-size: cover;
    background-position: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 100px;
    width: 100%;
  }
  .bg {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: rgba(48, 48, 48, 0.3);
    width: 100%;
    padding: 0;
    cursor: pointer;
    .serviceMore {
      color: #fff;
      text-align: right;
      font-weight: 500;
      padding-right: 10px;
    }
    .notice-name {
      color: #fff;
      z-index: 999;
      width: 100%;
      text-align: center;
      vertical-align: center;
      line-height: 70px;
    }
    .titleservice {
      max-width: 400px;
      min-width: 300px;
    }
  }
}

.bgblack {
  border: 1px solid #636364;
}
.bgbiue {
  border: 1px solid #3494ec;
}
.margin-top {
  margin-top: 50px;
}
//公共样式
.notice {
  background-color: #fff;
  border-radius: 25px;

  //   margin-top: 50px;
  //   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  .notice-header {
    // padding-left: 50px;
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid #3494ec;
    background-size: cover;
  }
  .box {
    height: 80px;
    line-height: 80px;
  }
  .notice-title {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    color: #4d4a4a;
    .pic {
      width: 20px;
      height: 20px;
      padding-left: 10px;
      align-items: center;
    }
  }
  .more {
    font-size: 18px;
    padding-right: 30px;
    cursor: pointer;
    text-decoration: none;
    color: #333;
  }
  .notice-content {
    padding: 20px;
    .notice-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;
      .title {
        font-size: 16px;
        display: flex;
        align-items: center;
        text-align: left;
        .titlename {
          max-width: 450px;
          min-width: 200px;
        }
        .cirle {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #727272;
          //   border-radius: 50%;
          line-height: 28px;
          margin-right: 20px;
        }
        .dataTime {
          font-size: 16px;
          color: #222121;
        }
      }
    }
  }
}
.titlename {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}
</style>
